@font-face {
    font-family: "pbslm-icons";
    src: url("https://d43fweuh3sg51.cloudfront.net/defaults/custom-font-icons/v23/pbslm-icons.eot?17f904ec2e270827ce632c4c12eb8a0a#iefix")
            format("embedded-opentype"),
        url("https://d43fweuh3sg51.cloudfront.net/defaults/custom-font-icons/v23/pbslm-icons.woff2?17f904ec2e270827ce632c4c12eb8a0a")
            format("woff2"),
        url("https://d43fweuh3sg51.cloudfront.net/defaults/custom-font-icons/v23/pbslm-icons.woff?17f904ec2e270827ce632c4c12eb8a0a")
            format("woff"),
        url("https://d43fweuh3sg51.cloudfront.net/defaults/custom-font-icons/v23/pbslm-icons.ttf?17f904ec2e270827ce632c4c12eb8a0a")
            format("truetype"),
        url("https://d43fweuh3sg51.cloudfront.net/defaults/custom-font-icons/v23/pbslm-icons.svg?17f904ec2e270827ce632c4c12eb8a0a#pbslm-icons")
            format("svg");
}

i[class^="pbslm-"]:before,
i[class*=" pbslm-"]:before {
    font-family: pbslm-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pbslm-back-10-vjs:before {
    content: "\f102";
}
.pbslm-cc-settings-vjs:before {
    content: "\f103";
}
.pbslm-close-circle:before {
    content: "\f104";
}
.pbslm-closed-caption-off-vjs:before {
    content: "\f105";
}
.pbslm-closed-caption-on-vjs:before {
    content: "\f106";
}
.pbslm-external-link:before {
    content: "\f107";
}
.pbslm-full-screen-vjs:before {
    content: "\f108";
}
.pbslm-globe:before {
    content: "\f109";
}
.pbslm-ico_info:before {
    content: "\f10a";
}
.pbslm-ico_save:before {
    content: "\f10b";
}
.pbslm-ico_support:before {
    content: "\f10c";
}
.pbslm-icon-arrow-down:before {
    content: "\f10d";
}
.pbslm-icon-arrow-left:before {
    content: "\f10e";
}
.pbslm-icon-arrow-right:before {
    content: "\f10f";
}
.pbslm-icon-assignments:before {
    content: "\f110";
}
.pbslm-icon-audio:before {
    content: "\f111";
}
.pbslm-icon-collection:before {
    content: "\f112";
}
.pbslm-icon-dashboard:before {
    content: "\f113";
}
.pbslm-icon-document:before {
    content: "\f114";
}
.pbslm-icon-email:before {
    content: "\f115";
}
.pbslm-icon-favorite:before {
    content: "\f116";
}
.pbslm-icon-favorited:before {
    content: "\f117";
}
.pbslm-icon-image:before {
    content: "\f118";
}
.pbslm-icon-interactive-lesson:before {
    content: "\f119";
}
.pbslm-icon-interactive:before {
    content: "\f11a";
}
.pbslm-icon-lesson-plan:before {
    content: "\f11b";
}
.pbslm-icon-link:before {
    content: "\f11c";
}
.pbslm-icon-media-gallery:before {
    content: "\f11d";
}
.pbslm-icon-menu:before {
    content: "\f11e";
}
.pbslm-icon-puzzle:before {
    content: "\f11f";
}
.pbslm-icon-share-and-assign:before {
    content: "\f120";
}
.pbslm-icon-social:before {
    content: "\f121";
}
.pbslm-icon-standards:before {
    content: "\f122";
}
.pbslm-icon-support-materials:before {
    content: "\f123";
}
.pbslm-icon-video:before {
    content: "\f124";
}
.pbslm-icon-website:before {
    content: "\f125";
}
.pbslm-pause-vjs:before {
    content: "\f126";
}
.pbslm-play-vjs:before {
    content: "\f127";
}
.pbslm-resize-screen-vjs:before {
    content: "\f128";
}
.pbslm-settings-vjs:before {
    content: "\f129";
}
.pbslm-uni74:before {
    content: "\f12a";
}
.pbslm-volume-high-vjs:before {
    content: "\f12b";
}
.pbslm-volume-low-vjs:before {
    content: "\f12c";
}
.pbslm-volume-mute-vjs:before {
    content: "\f12d";
}
.pbslm-volume-vjs:before {
    content: "\f12e";
}
.pbslm-z-minus-circle:before {
    content: "\f12f";
}
.pbslm-z-plus-circle:before {
    content: "\f130";
}
.pbslm-z-printer:before {
    content: "\f131";
}
.pbslm-zz-back-to-top-arrow:before {
    content: "\f101";
}
.pbslm-close-thin-cross:before {
    content: "\f132";
}
.pbslm-classes:before {
    content: "\f133";
}
.pbslm-dashboard2:before {
    content: "\f134";
}
.pbslm-folder:before {
    content: "\f135";
}
.pbslm-lesson-builder:before {
    content: "\f136";
}
.pbslm-smile-face:before {
    content: "\f137";
}
.pbslm-dots:before {
    content: "\f138";
}
.pbslm-duplicate:before {
    content: "\f139";
}
.pbslm-file-chart:before {
    content: "\f13a";
}
.pbslm-file-dots:before {
    content: "\f13b";
}
.pbslm-lock-closed:before {
    content: "\f13c";
}
.pbslm-lock-open:before {
    content: "\f13d";
}
.pbslm-brain:before {
    content: "\f13e";
}
.pbslm-facebook-simple:before {
    content: "\f13f";
}
.pbslm-pinterest-simple:before {
    content: "\f140";
}
.pbslm-focus-view:before {
    content: "\f141";
}
.pbslm-full-view:before {
    content: "\f142";
}
.pbslm-transcript:before {
    content: "\f143";
}
.pbslm-share-social:before {
    content: "\f145";
}
.pbslm-checked-checkbox:before {
    content: "\f144";
}
.pbslm-cut-eye:before {
    content: "\f146";
}
.pbslm-exclamation-warning-icon:before {
    content: "\f147";
}
